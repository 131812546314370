import React, { useState } from "react";
import Layout from "../components/layouts/Layout";
import { Helmet } from "react-helmet";
import axios from "../axiosConfig";

const GenerateKeys = () => {
  // Hardcoded products list
  const products = ["GTpro", "AnotherProduct"];

  const [product, setProduct] = useState(products[0]); // Default to the first product
  const [totalKeys, setTotalKeys] = useState(1);
  const [installLimit, setMachineLimit] = useState(1);
  const [generatedKeys, setGeneratedKeys] = useState([]);
  const [copySuccess, setCopySuccess] = useState(false);
  const [issuedTo, setIssuedTo] = useState("");
  const [error, setError] = useState(""); // For form validation errors
  const [successMessage, setSuccessMessage] = useState(""); // For success feedback

  const handleGenerateKey = async () => {
    // Reset messages
    setError("");
    setSuccessMessage("");

    // Basic validation
    if (!issuedTo.trim()) {
      setError("Issued To field cannot be empty.");
      return;
    }

    try {
      const response = await axios.post("/api/systemstraders/generate", {
        product,
        totalKeys,
        installLimit,
        issuedTo,
      });
      setGeneratedKeys(response.data);
      setSuccessMessage("Keys generated successfully!");
      // Optionally, reset the form fields
      // setProduct(products[0]);
      // setTotalKeys(1);
      // setMachineLimit(1);
      // setIssuedTo("");
    } catch (error) {
      console.error("Error generating key:", error);
      setError(
        error.response?.data?.message ||
          "An error occurred while generating keys."
      );
    }
  };

  const handleCopyKeys = () => {
    if (generatedKeys.length === 0) {
      setError("No keys to copy.");
      return;
    }
    navigator.clipboard
      .writeText(generatedKeys.join("\n"))
      .then(() => {
        setCopySuccess(true);
        setTimeout(() => setCopySuccess(false), 2000); // Reset after 2 seconds
      })
      .catch((error) => {
        console.error("Error copying keys:", error);
        setError("Failed to copy keys.");
      });
  };

  return (
    <Layout>
      <Helmet>
        <meta charset="UTF-8" />
        <title>Generate Keys | SystemTraders</title>
      </Helmet>

      <div className="content-body">
        <div className="container-fluid">
          <div className="row page-titles mx-0">
            <div className="col-sm-6 p-md-0">
              <div className="welcome-text">
                <h4>Generate Keys</h4>
                <p className="mb-0">Generate keys for SystemTraders products</p>
              </div>
            </div>
            <div className="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="index.html">Dashboard</a>
                </li>
                <li className="breadcrumb-item active">
                  <a href="#">Generate Keys</a>
                </li>
              </ol>
            </div>
          </div>

          <div className="row">
            {/* Form Section */}
            <div className="col-lg-12">
              <form>
                <div className="row">
                  {/* Product Selection */}
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Product:</label>
                      <select
                        className="form-control"
                        value={product}
                        onChange={(e) => setProduct(e.target.value)}
                      >
                        {products.map((product) => (
                          <option key={product} value={product}>
                            {product}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  {/* Number of Keys */}
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Number of Keys:</label>
                      <input
                        type="number"
                        className="form-control"
                        value={totalKeys}
                        onChange={(e) => {
                          const value = parseInt(e.target.value);
                          if ((value > 0 && value <= 5000) || isNaN(value))
                            setTotalKeys(value);
                        }}
                        onBlur={(e) => {
                          let value = parseInt(e.target.value);
                          if (isNaN(value) || value < 1) {
                            value = 1;
                            setTotalKeys(value);
                          }
                        }}
                        min="1"
                        max="5000"
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  {/* Machine Limit */}
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Machine Limit:</label>
                      <input
                        type="number"
                        className="form-control"
                        value={installLimit}
                        onChange={(e) => {
                          const value = parseInt(e.target.value);
                          if ((value > 0 && value <= 100) || isNaN(value))
                            setMachineLimit(value);
                        }}
                        onBlur={(e) => {
                          let value = parseInt(e.target.value);
                          if (isNaN(value) || value < 1) {
                            value = 1;
                            setMachineLimit(value);
                          }
                        }}
                        min="1"
                        max="100"
                      />
                    </div>
                  </div>

                  {/* Issued To */}
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Issued To:</label>
                      <input
                        type="text"
                        className="form-control"
                        value={issuedTo}
                        onChange={(e) => setIssuedTo(e.target.value)}
                        placeholder="Enter recipient's name or identifier"
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="form-group col-4">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={handleGenerateKey}
                    >
                      Generate Key
                    </button>
                  </div>

                  {/* Error Message */}
                  {error && (
                    <div className="col-8">
                      <div className="alert alert-danger" role="alert">
                        {error}
                      </div>
                    </div>
                  )}

                  {/* Success Message */}
                  {successMessage && (
                    <div className="col-8">
                      <div className="alert alert-success" role="alert">
                        {successMessage}
                      </div>
                    </div>
                  )}
                </div>

                {/* Generate Button */}
              </form>
            </div>

            {/* Generated Keys Section */}
            <div className="col-lg-12 mt-4">
              <div className="form-group">
                <label>Generated Keys:</label>
                <textarea
                  className="form-control"
                  rows="5"
                  value={generatedKeys.join("\n")}
                  readOnly
                ></textarea>
              </div>

              <button
                type="button"
                className="btn btn-primary"
                onClick={handleCopyKeys}
                disabled={generatedKeys.length === 0}
              >
                {copySuccess ? "Copied!" : "Copy"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default GenerateKeys;
